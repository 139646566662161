// 
// home.scss
//

.home {
    .w1280 {
        max-width: 1280px;
        margin: auto;
    }
    img.logo-home {
        width: 100%;
    }

    .the-problem {
        min-height: 495px;
        .title {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 45px;
            font-style: normal;
            font-weight: 400;
            font-size: 48px;
            line-height: 65px;
            text-align: center;
            color: #000000;
            flex-wrap: wrap;
            gap: 20px;
            padding-bottom: 33px;

            > div {
                flex: 1;
                display: flex;
                justify-content: center;
            }

            button {
                height: 55px;
                width: 230px;
            }
        }

        .description {
            padding: 0 60px;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            color: #6C757D;
        }
    }

    .the-solution {
        background-color: #F4F6FB;
        padding: 67px 0;
        min-width: 755px;

        > div {
            display: flex;
            padding: 0 145px;
            gap: 24px;
        }

        .left-screen {
            width: 341px;
            .title {
                font-style: normal;
                font-weight: 400;
                font-size: 48px;
                color: #000000;
                text-align: center;
            }

            .description {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                color: #6C757D;
                white-space: pre-line
            }

            .left-screen-btn {
                display: flex;
                justify-content: center;
            }

            button {
                margin-top: 40px;
                height: 55px;
                width: 230px;
            }
        }

        .right-screen {
            flex: 1;
            position: relative;

            .dashboard1 {
                position: absolute;
                left: 108px;
            }

            .dashboard2 {
                position: relative;
            }

            img {
                min-width: 350px;
                width: 100%;
                max-width: calc(100vw - 48px);
            }
        }
    }

    .how-it-works {
        padding: 67px 0;

        .title {
            font-style: normal;
            font-weight: 400;
            font-size: 48px;
            text-align: center;
            color: #000000;
        }

        .cards {
            display: flex;
            justify-content: center;
            gap: 55px;
            padding-top: 112px;

            > div:nth-of-type(odd) {
                background-color: #F4F6FB;
                padding: 0 40px;
            }

            img {
                position: relative;
                bottom: 64px;
            }

            .sub-title {
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                text-align: center;
                color: #000000;
                max-width: 280px;
                padding-bottom: 12px;
            }

            .description {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                text-align: center;
                color: #6C757D;
                max-width: 280px;
                padding-bottom: 52px;
            }
        }
        
        .how-it-works-btn {
            padding-top: 50px;
            display: flex;
            justify-content: center;
            gap: 24px;

            .btn-secondary {
                background: #000000;
            }

            button {
                height: 55px;
                width: 230px;
            }
        }
    }

    .pricing {
        padding-bottom: 40px;

        .title {
            font-style: normal;
            font-weight: 400;
            font-size: 48px;
            text-align: center;
            color: #000000;
            padding-bottom: 60px;
        }

        .prices {
            display: flex;
            justify-content: center;
            .mdi-check {
                color: #00D196;
            }

            .mdi-close {
                color: #FA5C7C;
            }

            > div {
                width: 326px;
            }

            .free {
                margin-bottom: 50px;
                .plan-title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14.4px;
                    text-align: center;
                    color: #242525;
                    padding-top: 21px;
                }

                .plan-value {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 48px;
                    text-align: center;
                    color: #495057;
                    padding-top: 27px;
                }

                .check-description {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                }

                .checks {
                    display: flex;
                    gap: 18px;
                    padding: 0 33px;
                    > div {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14.4px;
                        color: #495057;
                    }
                }

                .plan-btn {
                    padding-top: 50px;
                    display: flex;
                    justify-content: center;

                    > button {
                        border-radius: 20px;
                        width: 150px;
                    }
                }
            }

            .recommended {
                position: relative;
                bottom: 30px;
                background: #313A46;
                border-radius: 20px;
                padding-bottom: 30px;
                .header {
                    height: 30px;
                    border-radius: 20px 20px 0 0;
                    padding: 5px;
                    background: #727CF5;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14.4px;
                    text-align: center;
                    color: #FFFFFF;
                }

                .month-year {
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    .btn-disabled {
                        color: #6C757D;
                        background-color: #1B222C;
                    }

                    > button {
                        margin-top: 12px;
                        height: 30px;
                        gap: 10px;
                        border-radius: 20px;
                    }
                }

                .plan-title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14.4px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    padding-top: 21px;
                }

                .plan-value {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 48px;
                    text-align: center;
                    color: #FFFFFF;
                }

                .plan-time {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    bottom: 12px;
                }

                .check-description {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                }

                .checks {
                    display: flex;
                    gap: 18px;
                    padding: 0 33px;
                    > div {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14.4px;
                        color: #b1b5b9;
                    }

                    .unlimited {
                        background-color: #1b222c;
                        padding: 2px 5px;
                        border-radius: 5px;
                    }
                }

                .plan-btn {
                    padding-top: 50px;
                    display: flex;
                    justify-content: center;

                    > button {
                        border-radius: 20px;
                        width: 150px;
                    }
                }
            }

            .executive {
                box-shadow: 0px 0px 35px rgba(154, 161, 171, 0.14902);
                border-radius: 20px;
                padding-bottom: 30px;
                height: 100%;

                .month-year {
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    .btn-disabled {
                        color: #6C757D;
                        background-color: #F2F2F2;
                    }

                    > button {
                        margin-top: 12px;
                        height: 30px;
                        gap: 10px;
                        border-radius: 20px;
                    }
                }

                .plan-title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14.4px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #242525;
                    padding-top: 21px;
                }

                .plan-value {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 48px;
                    text-align: center;
                    color: #495057;
                }

                .plan-time {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    bottom: 12px;
                }

                .check-description {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                }

                .checks {
                    display: flex;
                    gap: 18px;
                    padding: 0 33px;
                    > div {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14.4px;
                        color: #495057;
                    }

                    .unlimited {
                        background-color: #1b222c;
                        color: #ffffff;
                        padding: 2px 5px;
                        border-radius: 5px;
                    }
                }

                .plan-btn {
                    padding-top: 50px;
                    display: flex;
                    justify-content: center;

                    > button {
                        border-radius: 20px;
                        width: 150px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .home {
        .the-problem {
            .title {
                flex-direction: column;
                gap: 20px;
            }

            .description {
                padding: 0;
            }
        }

        .the-solution {
            > div {
                display: flex;
                flex-direction: column;
                padding: 0;
            }
        }
    }

    .how-it-works {
        .cards {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }

    .pricing {
        .prices {
            display: flex;
            align-items: center;
            flex-direction: column;
            > div {
                width: 100%;
            }
        }
    }
}