.fundamental-form-page-container {
    margin: 0px 16px 0px 8px;

    .fundamental-form-container {
        background: #FFFFFF;
        box-shadow: 0px 0px 35px rgba(154, 161, 171, 0.14902);
        border-radius: 4px;
        padding: 25px 25px 31px 20px;
    }
}

.fundamental-details-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;

    color: #495057;
}

@include media-breakpoint-down(sm) {
    .fundamental-details-container {
        justify-content: center;
    }
}
