.subscription-card-pricing {
    position: relative;
    display: flex;
    flex: 1;

    .subscription-card-pricing-plan-name {
        padding-bottom: 12px;
        margin: 0;
        font-size: 14.4px;
        line-height: 20px;
    }

    .subscription-card-pricing-plan-tag {
        align-self: stretch;
    }

    .subscription-card-pricing-icon {
        font-size: 22px;
        box-shadow: $shadow;
        height: 60px;
        display: inline-block;
        width: 60px;
        line-height: 56px;
        border-radius: 50%;
    }

    .no-price-extra-padding {
        margin-bottom: 48px;
    }

    .available-payment-frequency-button {
        width: 100px;
    }

    .subscription-card-pricing-price {
        margin-top: 21px;
        padding: 0;

        span {
            font-size: 48px;
            color: $gray-600;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
    }
    .subscription-card-pricing-features {
        color: $gray-600;
        list-style: none;
        margin: 0;
        padding: 20px 0 0 0;
        margin-bottom: 26px;
        text-align: left;
        li {
            display: flex;
            padding: 12px;
            i {
                margin-right: 16px;
            }
            span {
                margin-right: 4px;
                margin-left: -8px;
                padding: 2px 0px;
                border-radius: 5px;
                b {
                    margin: 0 8px 0;
                }
            }
        }
    }

    .subscription-card-submit-button {
        width: 150px;
        margin-top: auto;
        margin-bottom: 10px;
    }
}

.subscription-card-pricing-recommended {
    @include media-breakpoint-up(md) {
        margin-top: -1.9375rem;
    }

    .subscription-card-pricing-plan-tag {
        background-color: $primary;
        color: $white;
        padding: 5px 0;
        font-weight: 700;
        border-radius: 20px 20px 0 0;
        margin: -1.5rem -1.5rem 1.5rem -1.5rem;
    }
}
