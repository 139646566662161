// 
// dropdown.scss
//

.dropdown-box-container {
    height: 44px;

    margin-bottom: 25px;
    margin-right: 49px;
}

.dropdown-label-container  {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: #6C757D;
}

.dropdown-toggle-container {
    padding-left: 2px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
}


@include media-breakpoint-down(sm) {
    .dropdown-box-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        width: auto !important;
        margin-right: 0px;
        min-width: auto !important;
    }
    .dropdown-toggle-container, .dropdown-item-container, .dropdown-label-container {
        width: 280px !important;
    }
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0ACF97;
    border-radius: 6px;
}

.dropdown-menu {
    box-shadow: $shadow;
}

// Dropdown Animated (Custom)
.dropdown-menu-animated {
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
    margin: 0;
    position: absolute;
    z-index: 1000;
    
    &.show {
        top: 100%!important;
    }

    i {
        display: inline-block;
    }

    &.dropdown-menu-right {
        right: 0!important;
        left: auto!important;
    }
    
    &.dropdown-menu[x-placement^=right],
    &.dropdown-menu[x-placement^=top],
    &.dropdown-menu[x-placement^=left]{
        top: auto !important;
        animation: none !important;
    }
}

@keyframes DropDownSlide {
    100% {
        transform: translateY(0)
    }
    0% {
        transform: translateY(20px)
    }
}


// Dropdown Large (Custom)
@media (min-width: 600px) {
    .dropdown-lg {
        width: $dropdown-lg-width;
    }
}

// Dropdown with Icons
.dropdown-icon-item {
    display: block;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    display: block;
    border: 1px solid transparent;
    color: $dropdown-link-color;

    img {
        height: 24px;
    }

    span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:hover {
        background-color: $dropdown-link-hover-bg;
        color: $dropdown-link-hover-color;
    }
}


// Dropdown arrow hide
.arrow-none {
    &:after {
        display: none;
    }
}
