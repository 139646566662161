.form-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 20px;

    color: #6C757D;
}

.form-group-title {
    margin-top: 12px;
    margin-bottom: 24px;

    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14.4px;
    line-height: 20px;

    color: #495057;
}

.client-add-edit-container {
    margin: 0px 16px 0px 8px;
}

.client-form-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 35px rgba(154, 161, 171, 0.14902);
    border-radius: 4px;
    padding: 25px 25px 31px 20px;
}

.client-form-frame-container {
    background: #EEF2F7;
    display: flex;
    flex: 1;

    padding-left: 0px;
    padding-right: 0px;

    .client-form-frame {
        background: #EEF2F7;
        flex: 1;
        object-fit: cover;
        object-position: 0 0;
    }
}

.profile-image-container {
    height: 94px;
    width: 94px;

    border-radius: 50%;
    cursor: pointer;
    outline: none !important;


    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-image-placeholder-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    background: #191F27;
    height: 91px;
    width: 91px;

    border-radius: 50%;
}

.profile-image {
    height: 91px;
    width: 91px;

    border-radius: 50%;
    cursor: pointer;
    outline: none !important;
}

.profile-image-no-cursor {
    height: 91px;
    width: 91px;

    border-radius: 50%;
    outline: none !important;
}

.dashed-red-border {
    border: 3px dashed red;
}

.profile-image-placeholder {
    font-size: 22px;
    color: #F2F2F2;
}

.profile-email-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 20px;
    text-align: center;

    color: #6C757D;
}

.profile-image-uploader-container {
    display: flex;
    flex-direction: column;
}

.profile-name-label {
    margin-top: 8px;
}

.files {
    padding-top: 12px;
    .file {
        display: flex;
        gap: 12px;
        align-items: center;

        .path {
            flex: 1;   
        }

        .mdi {
            font-size: 20px;
        }

        .mdi-folder-image {
            color: #4759db;
        }

        .mdi-file-pdf {
            color: #dd3939
        }

        .mdi-delete {
            color: #dd3939;
            cursor: pointer;
        }

    }
}

@include media-breakpoint-down(sm) {
    .client-form-frame-container {
        display: none;
    }
    .profile-image-uploader-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        margin-bottom: 16px;
    }
}
