.list-control-container {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    margin-right: 0px;
}

.list-pagination-container {
    height: 50px;
    margin-right: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}

@include media-breakpoint-down(sm) {
    .list-control-container {
        justify-content: space-between;
    }
}
