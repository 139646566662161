.horizontal-steps-container {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 0px 10px 2px #ddd;
    padding: 12px 16px;
    border-radius: 30px;

    .round-step-image {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #727cf5;
        border: 2px solid #727cf5;
        border-radius: 20px;
    }
    .round-step-active-icon, .round-step-inactive-icon {
        width: 20px;
        height: 20px;
        border-radius: 20px;
    }
    .round-step-active-icon {
        animation-delay: 2s;
        box-shadow: 0px 0px 0px 5px #727cf5 inset;
        border: 2px solid #727cf5;
        transition: box-shadow 2s;
        transition: border 2s;
    }
    .round-step-inactive-icon {
        border: 2px solid #ccc;
        background-color: #fff;
    }
    .step-active-line, .step-inactive-line {
        display: flex;
        flex: 1;
        height: 2px;
    }
    .step-active-line {
        height: 3px;
        background-color: #727cf5;
    }
    .step-inactive-line {
        background-color: #ccc;
    }
}
