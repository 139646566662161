.vp-client-summary-container {
    display: flex;
    flex-direction: row;

    background: #FFFFFF;
    box-shadow: 0px 0px 35px rgba(154, 161, 171, 0.14902);
    border-radius: 4px;

    margin-bottom: 24px;
}

.vp-client-summary-emphasis {
    background: #0ACF97;
    border-radius: 4px 0px 0px 4px;

    width: 5px;
}

.vp-client-summary-inner {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 3px 16px;
}

.vp-client-summary-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 20px;

    color: #6C757D;

    padding-bottom: 8px;
}

.vp-client-summary-value {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;

    color: #495057;

    padding-bottom: 19px;
}

.tags {
    position: relative;
}

.tags .form-control {
    gap: 2px;
}

.hashtag-suggestions {
    position: absolute;
    z-index: 10000;
    width: 100%;

    > div {
        background: #e3e3e3;
        padding: 4px 16px;
        cursor: pointer;
    }

    > div:hover {
        background: #efefef;
    }
}

.badge.bg-secondary {
    color: #fff;
    background-color: #727cf5 !important;

    svg {
        fill: #fff !important;
    }
}