.vpx-container {
    display: flex;
    flex: 1;
}

.vpx-details-header {
    text-align: start;
    padding-bottom: 16px;
}


.vpx-details-header-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;

    color: #98A6AD;

    margin-top: 0px;
    margin-bottom: 4px;
}

.vpx-details-header-name {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;

    margin-top: 0px;
    margin-bottom: 12px;

    color: #495057;
}

.vpx-details-header-company {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    min-height: 36px;

    color: #495057;

    opacity: 0.5;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}

.vpx-icon-container {
    padding: 0px;

    .vpx-icon-button {
        font-size: 18px;
        line-height: 16px;

        color: white;
    }
}

.vpx-badge {
    align-self: center;
    padding: 12px;
}

.vpx-clickable {
    cursor: pointer;
}
