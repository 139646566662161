.client-card {
    background: #FFFFFF;
    box-shadow: 0px 0px 35px rgba(154, 161, 171, 0.14902);
    border-radius: 4px;
    min-width: 280px;
    padding: 25px 22px 21px 23px;
    border-width: 0px;
    cursor: pointer;
}

.inactive-color {
    color: #fa5c7c !important;
}

.client-card-container {
    margin-right: 24px;
    margin-bottom: 20px;
}

.client-card-header {
    text-align: start;
    padding-bottom: 16px;
    border-bottom: 1px solid #98A6AD;
}

.client-card-body {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
}

.client-vpc-total-title {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;

    color: #98A6AD;

    margin-bottom: 8px;
}

.client-description-container {
    text-align: start;
    margin-left: 0px;
    padding-left: 26px;
    padding-right: 0px;
}

.client-vpc-total-value {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;

    color: #6C757D;
}

.client-fx-factor-value {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;

    color: #6C757D;
}

.client-name-title {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;

    margin-top: 0px;
    margin-bottom: 2px;

    color: #495057;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.client-location-text {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    min-height: 36px;

    color: #495057;

    opacity: 0.5;

    margin-bottom: 14px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}

.client-card-description {
    width: 180px;
    overflow: hidden;
}

.client-create-vp-button {
    min-width: 96px;
    height: 32px;

    .client-create-vp-button-text {
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        text-align: center;

        color: #FFFFFF;
    }
}

.client-edit-button-contaienr {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    padding-top: 4px;

    .client-edit-button {
        width: 18px;
        height: 18px;
        font-size: 18px;
        line-height: 18px;

        cursor: pointer;

        color: #495057;
        opacity: 0.5;
    }
}

@include media-breakpoint-down(sm) {
    .client-card-container {
        display: flex;
        flex: 1;
        justify-content: center;
        margin-right: 0px;
    }
    .client-card {
        margin-right: 0px;
    }
}
