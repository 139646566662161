.value-proposition-container {
    display: flex;
    flex: 1;
    flex-direction: row;

    background: #FFFFFF;
    border-radius: 4px;

    max-width: 100%;
}

.client-vp-summary-container {
    width: 350px;

    background: #FFFFFF;
    box-shadow: 0px 0px 35px rgba(154, 161, 171, 0.14902);
    border-radius: 4px;
    padding-bottom: 8px;
}

.client-shared-vp-summary-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 35px rgba(154, 161, 171, 0.14902);
    border-radius: 4px;
    padding-bottom: 8px;

    padding: 21px 16px 12px 16px;
}

.vp-information-container {
    display: flex;
    flex: 1;
    flex-direction: column;

    padding: 21px 28px 24px 34px;
    overflow: hidden;
}

.client-vp-summary-title-container {
    display: flex;
    padding: 27px 0px;
}

.client-image-container {
    margin-left: 35px;
}

.client-name-title-container {
    padding-top: 12px;
    padding-left: 25px;
    margin-left: 0px;
}

.summary-item-title-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    color: #6C757D;

    width: 100%;
    margin-bottom: 4px;
}

.summary-item-value-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    color: #495057;

    width: 100%;
    margin-bottom: 16px;
}

.vp-title-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 33px;

    color: #495057;

    margin-bottom: 12px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fundamentals-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;

    color: #6C757D;
}

.fundamentals-badge {
    padding: 4px 8px;

    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 11.6px;
    line-height: 16px;
    text-align: center;
}

.vp-buttons-container {
    flex: 1;
    align-items: flex-end;
}

.fundamentals-link-container {
    height: 41px;
    text-align: left;
}

.hashtags {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    div {
        background: #4e5bf2;
        color: #fff;
        padding: 8px;
        border-radius: 6px;
    }
}

.link {
    color: #0000EE;
    text-decoration: underline;
    cursor: pointer;
}

@include media-breakpoint-down(sm) {
    .value-proposition-container {
        flex-direction: column;
        height: auto;
        margin-bottom: 8px;
    }

    .client-vp-summary-container {
        width: auto;
    }

    .fundamentals-badge-container {
        display: flex;
        gap: 6px;
        flex-direction: column;
        > div {
            padding-left: 0px;

            > label {
                width: 100px;
            }
        }
    }

    .vp-information-container {
        padding: 16px 10px 8px 10px;
    }

    .vp-buttons-container {
        margin-top: 16px;
        display: flex;
        align-items: flex-start;
        gap: 6px;

        > button {
            margin-left: 0 !important;
        }
    }

    .client-vp-summary-title-container {
        padding-top: 16px;
        padding-bottom: 12px;
    }

    .summary-item-value-label {
        margin-bottom: 8px;
    }
}
