.add-fundamental-button {
    background: #727CF5;
    box-shadow: 0px 2px 6px rgba(114, 124, 245, 0.498039);
    border-radius: 2px;
}

.fundalmentals-body-container {
    margin: 21px 0px 0px 0px;
    min-height: 100vh;
}

.fundament-card-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 35px rgba(154, 161, 171, 0.14902);
    border-radius: 4px;

    margin-bottom: 20px;
    margin-right: 13px;

    padding: 18px 22px 23px 24px;

    display: flex;
    flex-direction: column;
}

.fundament-date-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-size: 14.4px;
    line-height: 20px;

    color: #6C757D;
}

.fundament-name-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 33px;

    color: #495057;

    padding-top: 8px;
}

.fundamentals-page-tabs-container {
    border-bottom-width: 0px !important;

    .nav-link {
        padding-right: 30px;
        margin-right: 20px;
    }

    .nav-link.active {
        background-color: transparent !important;
    }
}
