// 
// page-head.scss
//

.page-title-box {
    margin-top: 24px;
    margin-bottom: 10px;
    .page-title-left-button {
        width: 24px;
        height: 24px;
        margin-top: 2px;
        margin-right: 13px;
        padding: 0px;
        background-color: transparent;
        border-color: transparent;
        float: left;
    }
    .page-title-left-button-icon {
        font-size: 24px;
        line-height: 24px;
        margin: 0;
        color: #000000;
        opacity: 0.3;
    }
    .page-title {
        font-size: 24px;
        line-height: 32.74px;
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: $page-title-color;
    }
    .page-title-right {
        float: right;
    }
    .page-title-right-button {
        float: right;
    }
    .breadcrumb {
        padding-top: 8px;
    }
}

.page-title-box-sm {
    .page-title {
        line-height: 1 !important;
    }
    .page-title-right {
        float: right;
    }
    .page-title-right-button {
        float: right;
    }
    .breadcrumb {
        padding-top: 0;
        margin-top: -3px !important;
    }
}


.text-title {
    color: $text-title-color;

    &:hover {
        color: $text-title-color;
    }
}

@include media-breakpoint-down(sm) {
    .page-title-box {
        .page-title-left-button {
            margin-top: 20px;
        }
        .page-title {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 70px;
        }
        .breadcrumb {
            display: none;
        }
        .page-title-right {
            display: none;
        }
        .page-title-right-button {
            margin-top: 15px;
        }
    }
}

@media (max-width: 419px) {
    .page-title-box .breadcrumb {
        display: none;
    }
}
