.dashboard-section-title {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;

    text-transform: uppercase;

    color: #6C757D;
}

.portifolio-card-icon {
    color: #727cf5;
    font-size: 20px;
    background-color: rgba(114, 124, 245, 0.25);
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 3px;
    display: inline-block;
}

.portifolio-title {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;

    color: #98A6AD;
    white-space: pre-wrap;
    min-width: 150px;
}

.chart-title {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;

    color: #98A6AD;

    white-space: pre-line;
    margin-bottom: 17px;
}

.chart-total-value-title {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;

    color: #6C757D;
    margin-bottom: 35px;
}

.chart-legend-text {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;

    color: #6C757D;
}

.chart-legend-container {
    display: flow-root;
}
