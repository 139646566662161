.vp-details-table-text {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;

    font-size: 0.75rem;
}

.vp-details-card-title {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;

    color: #98A6AD;

    margin-bottom: 8px;
}

.vp-details-card-value {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;

    white-space: nowrap;

    color: #6C757D;
}

.fundamental-created-text {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;

    color: #98A6AD;
}

/* The alert message box */
.company-alert {
    float: right;
    padding-top: 4px;
    font-size: 10px;
    color: red;
}

.company-filter-box {
    border-radius: 25px;
    border: 2px solid gray;
    padding: 12px 24px;
    margin-top: 16px;

    .container-label {
        font-weight: bold;
        margin-bottom: 8px;
    }
}
