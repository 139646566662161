.client-details-component-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 100px;

    background: #EEF2F7;
    border-radius: 0px 0px 4px 0px;
}

.client-details-name-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;

    color: #6C757D;

    padding: 23px 0px 12px 0;
}

.client-details-advertising-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 20px;
    text-align: center;

    color: #6C757D;

    padding-bottom: 21px;
}

.client-details-results-title-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;

    color: #495057;

    padding: 54px 0px 30px 0px;
}

.client-details-item-title-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    color: #6C757D;

    width: 100%;
    margin-bottom: 4px;
}

.client-details-item-value-label {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    color: #495057;

    width: 100%;
    margin-bottom: 16px;
}

@include media-breakpoint-down(sm) {
    .client-details-component-container {
        padding-top: 16px;
        padding-bottom: 16px;
    }
}
